import * as Sentry from '@sentry/browser';

export const initSentry = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        tracesSampleRate: 1.0,
        profilesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        enabled: process.env.NODE_ENV !== 'development',
    });
};

export const captureException = error => Sentry.captureException(error);
